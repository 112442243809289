<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        A compound is known to consist solely of carbon, hydrogen, and nitrogen. Through elemental
        analysis, it was determined that the compound is composed of
        <stemble-latex :content="pctCarbon" />
        carbon and
        <stemble-latex :content="pctHydrogen" />
        hydrogen, with the remainder being nitrogen.
      </p>

      <p class="mb-3">a) What is the empirical formula of this compound?</p>

      <p class="pl-4 mb-3">
        <calculation-input
          v-model="inputs.nCarbonEF"
          prepend-text="$\text{C:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nHydrogenEF"
          prepend-text="$\text{H:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nNitrogenEF"
          prepend-text="$\text{N:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />
      </p>

      <p class="pl-4 mb-7">
        <stemble-latex style="font-size: 11px" content="$\textbf{Empirical Formula: }$" />
        <chemical-latex v-if="EFnotation" style="font-size: 11px" :content="EFnotation" />
      </p>

      <p class="mb-3">b) What is the molecular weight of the empirical formula?</p>

      <calculation-input
        v-model="inputs.molWeightEF"
        prepend-text="$\text{Mol. Weight:}$"
        append-text="$\text{g/mol}$"
        class="pl-4 mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) If the molar mass of this compound is
        <stemble-latex :content="molarMass" />
        what is its molecular formula?
      </p>

      <p class="pl-4 mb-3">
        <calculation-input
          v-model="inputs.nCarbonMF"
          prepend-text="$\text{C:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nHydrogenMF"
          prepend-text="$\text{H:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />

        <calculation-input
          v-model="inputs.nNitrogenMF"
          prepend-text="$\text{N:}$"
          class="d-sm-inline-block mr-6"
          style="width: 90px"
          :disabled="!allowEditing"
        />
      </p>

      <p class="pl-4">
        <stemble-latex style="font-size: 11px" content="$\textbf{Molecular Formula: }$" />
        <chemical-latex v-if="MFnotation" style="font-size: 11px" :content="MFnotation" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question260b',
  components: {
    StembleLatex,
    CalculationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        nCarbonEF: null,
        nHydrogenEF: null,
        nNitrogenEF: null,
        molWeightEF: null,
        nCarbonMF: null,
        nHydrogenMF: null,
        nNitrogenMF: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    }, // 1 = C6H12O6, 2 = C4H8O2, 3 = C4H4O8, 4 = C6H8O4, 5 = C2H2O4, 6 = C6H9O6
    versionData() {
      switch (this.versionNumber.value) {
        case 1:
          // 1 = C6H12N3
          return {
            molarMass: '$\\text{126.18 g/mol,}$',
            pctCarbon: '$\\text{57.11\\%}$',
            pctHydrogen: '$\\text{9.59\\%}$',
          };
        case 2:
          // 2 = C2H6N2
          return {
            molarMass: '$\\text{58.08 g/mol,}$',
            pctCarbon: '$\\text{41.36\\%}$',
            pctHydrogen: '$\\text{10.41\\%}$',
          };
        case 3:
          // 3 = C4H12N2
          return {
            molarMass: '$\\text{88.15 g/mol,}$',
            pctCarbon: '$\\text{54.50\\%}$',
            pctHydrogen: '$\\text{13.72\\%}$',
          };
        case 4:
          // 4 = C6H18N4
          return {
            molarMass: '$\\text{146.24 g/mol,}$',
            pctCarbon: '$\\text{49.28\\%}$',
            pctHydrogen: '$\\text{12.41\\%}$',
          };
        case 5:
          // 5 = C3H15N9
          return {
            molarMass: '$\\text{177.21 g/mol,}$',
            pctCarbon: '$\\text{20.33\\%}$',
            pctHydrogen: '$\\text{8.53\\%}$',
          };
        case 6:
          // 6 = C2H8N4
          return {
            molarMass: '$\\text{88.11 g/mol,}$',
            pctCarbon: '$\\text{27.26\\%}$',
            pctHydrogen: '$\\text{9.15\\%}$',
          };
        case 7:
          // 7 = C10H24N4
          return {
            molarMass: '$\\text{200.33 g/mol,}$',
            pctCarbon: '$\\text{59.96\\%}$',
            pctHydrogen: '$\\text{12.08\\%}$',
          };
        case 8:
          // 8 = C6H9N3
          return {
            molarMass: '$\\text{123.16 g/mol,}$',
            pctCarbon: '$\\text{58.52\\%}$',
            pctHydrogen: '$\\text{7.37\\%}$',
          };
        case 9:
          // 9 = C6H15N3
          return {
            molarMass: '$\\text{129.21 g/mol,}$',
            pctCarbon: '$\\text{55.78\\%}$',
            pctHydrogen: '$\\text{11.70\\%}$',
          };
        case 10:
          // 10 = C8H20N4
          return {
            molarMass: '$\\text{172.27 g/mol,}$',
            pctCarbon: '$\\text{55.78\\%}$',
            pctHydrogen: '$\\text{11.70\\%}$',
          };
        default:
          return {
            molarMass: null,
            pctCarbon: null,
            pctHydrogen: null,
          };
      }
    },
    molarMass() {
      return this.versionData.molarMass;
    },
    pctCarbon() {
      return this.versionData.pctCarbon;
    },
    pctHydrogen() {
      return this.versionData.pctHydrogen;
    },
    numCEF() {
      return this.buildAtom('C', this.inputs.nCarbonEF);
    },
    numHEF() {
      return this.buildAtom('H', this.inputs.nHydrogenEF);
    },
    numNEF() {
      return this.buildAtom('N', this.inputs.nNitrogenEF);
    },
    EFnotation() {
      return `${this.numCEF}${this.numHEF}${this.numNEF}`;
    },
    numCMF() {
      return this.buildAtom('C', this.inputs.nCarbonMF);
    },
    numHMF() {
      return this.buildAtom('H', this.inputs.nHydrogenMF);
    },
    numNMF() {
      return this.buildAtom('N', this.inputs.nNitrogenMF);
    },
    MFnotation() {
      return `${this.numCMF}${this.numHMF}${this.numNMF}`;
    },
  },
  methods: {
    /**
     * @param {string} atomType
     * @param {string} number
     * @returns {string}
     */
    buildAtom(atomType, number) {
      if (number) {
        if (number === '1') {
          return atomType;
        } else {
          return atomType.concat(number);
        }
      }
      return `${atomType}_0`;
    },
  },
};
</script>
